@use "~bulma/sass/utilities/initial-variables" as iv;
@use '~bulma/sass/elements/tag';
@forward "~bulma/sass/form";
@use "bulma/sass/utilities/extends";

.tags {
  .tag {
    font-weight: 600;
  }

  ul {
    @extend .input;
    @extend %overlay;
    position: absolute;
    z-index: 2;
    max-height: 250px;
    height: auto;
    min-height: 50px;
    margin-top: 28px;
    li {
      display: block;
      width: 100%;
      cursor: pointer;
      padding: {
        top: 0;
        bottom: 0;
        left: .5em;
        right: .5em;
      };
      border-left: 1px solid white;
      &:hover {
        background-color: white;
        border-left: 1px solid iv.$orange;
      }
    }
  }
}
