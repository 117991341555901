.main-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  .navbar {
    flex: none;
  }
  .main-content {
    flex: 2 1 auto;
    .section-sidebar {
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}
