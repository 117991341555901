.is-flex-grow-1 {
  flex-grow: 1;
}

.m-1 {
  margin: 0.25rem;
}

.m-2 {
  margin: 0.5rem;
}

.m-3 {
  margin: 0.75rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.ml-4 {
  margin-left: 1rem;
}

.mr-2 {
  margin-right: 0.5rem;
}
.mr-3 {
  margin-right: 0.75rem;
}

.mr-4 {
  margin-right: 1rem;
}

.tree-list {
  ul {
    margin-left: 1.5rem;
  }
}

label.radio {
  display: flex-inline;

  input[type="radio"] {
    display: inline-block;
    margin-right: 0.5rem;
  }
}

ul.multiple-chapter-upload-container {
  li {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-bottom: 1px solid lightgray;
    padding-top: 0.25rem;
    padding-bottom: 0.5rem;

    div {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      span.is-auto {
        flex-grow: 1;
      }

      label  {
        display: flex;
        gap: 0.5rem;
      }
    }
  }
}

.progress-container {
  display: inline-block;
  width: 100%;
  max-width: 200px;
}
