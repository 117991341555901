@use "~bulma/sass/utilities/initial-variables" as iv;
@use '~bulma/sass/elements/button';

.button {
  border-radius: iv.$radius;
  > .icon {
    margin-bottom: 0.05em;
  }
  &.is-outlined {
    &.is-loading {
      &:focus {
        &::after {
          border-left-color: iv.$white !important;
          border-bottom-color: iv.$white !important;
        }
      }
    }
  }
}
