@use '~bulma/sass/elements/table';

.table {
  th,
  td {
    &:last-child {
      text-align: right;
    }
  }
  td {
    vertical-align: middle;
  }
}
