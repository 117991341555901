@use "~bulma/sass/utilities/css-variables" as cv;
@use "~bulma/sass/utilities/derived-variables" as dv;
@use "~bulma/sass/utilities/initial-variables" as iv;
@use "bulma/sass/utilities/extends";

$pageloader-opacity: 1 !default;

// keyframes mixin
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}

.pageloader {
  @extend %overlay;
  position: fixed;
  padding-top: 2em;
  background: dv.$primary;
  background: rgba(dv.$primary, $pageloader-opacity);

  @each $name, $pair in dv.$colors {
    &.#{iv.$class-prefix}is-#{$name} {
      $color: #{cv.getVar($name)};
      $color-invert: #{cv.getVar($name, "", "-invert")};

      &.is-#{$name} {
        background-color: $color;
        background: rgba($color, $pageloader-opacity);

        &::after {
          border-color: $color-invert;
          animation: loader-figure-#{$name} 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        .title {
          color: $color-invert;
        }
      }
    }
  }

  z-index: 999998;
  transition: transform .35s ease-out,-webkit-transform .35s ease-out;
  will-change: transform;

  &:not(.is-left-to-right),
  &:not(.is-right-to-left),
  &:not(.is-bottom-to-top) {
    transform: translateY(-100%)
  }
  &.is-bottom-to-top {
    transform: translateY(100%)
  }
  &.is-left-to-right {
    transform: translateX(-100%)
  }
  &.is-right-to-left {
    transform: translateX(100%)
  }
  &.is-active {
    &:not(.is-left-to-right),
    &:not(.is-right-to-left) {
      transform: translateY(0);
    }
    &.is-left-to-right,
    &.is-right-to-left {
      transform: translateX(0)
    }
  }
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    border-radius: 100%;
    content: '';
    z-index: 9999;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 0 solid iv.$white;
    animation: loader-figure 1.15s infinite cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    margin: 2em 0 0 0;
    font-size: 0.875em;
    letter-spacing: 0.1em;
    line-height: 1.5em;
    color: iv.$white;
    white-space: nowrap;
  }

  @include keyframes(loader-figure) {
    0% {
      height: 0;
      width: 0;
      background-color: iv.$white;
    }
    29% {
      background-color: iv.$white;
    }
    30% {
      height: 2em;
      width: 2em;
      background-color: transparent;
      border-width: 1em;
      opacity: 1;
    }
    100% {
      height: 2em;
      width: 2em;
      border-width: 0;
      opacity: 0;
      background-color: transparent;
    }
  }
}

@each $name, $pair in dv.$colors {
  $color: #{cv.getVar($name)};
  $color-invert: #{cv.getVar($name, "", "-invert")};

  @include keyframes(loader-figure-#{$name}) {
    0% {
      height: 0;
      width: 0;
      background-color: $color-invert;
    }
    29% {
      background-color: $color-invert;
    }
    30% {
      height: 2em;
      width: 2em;
      background-color: transparent;
      border-width: 1em;
      opacity: 1;
    }
    100% {
      height: 2em;
      width: 2em;
      border-width: 0;
      opacity: 0;
      background-color: transparent;
    }
  }
}
