@charset "utf-8";

@use "~bulma/sass/utilities" with (
   // Colors
  $black: hsl(0, 0%, 4%),
  $black-bis: hsl(0, 0%, 7%),
  $black-ter: hsl(0, 0%, 14%),

  $grey-darker: hsl(0, 0%, 21%),
  $grey-dark: hsl(0, 0%, 29%),
  $grey: hsl(0, 0%, 48%),
  $grey-light: hsl(0, 0%, 71%),
  $grey-lighter: hsl(0, 0%, 86%),

  $white-ter: hsl(0, 0%, 96%),
  $white-bis: hsl(0, 0%, 98%),
  $white: hsl(0, 0%, 100%),

  $orange: hsl(14, 100%, 53%),
  $yellow: #f39c12,
  $green: hsl(141, 71%, 48%),
  $turquoise: hsl(171, 100%, 41%),
  $cyan: hsl(204, 86%, 53%),
  $blue: hsl(217, 71%, 53%),
  $purple: hsl(271, 100%, 71%),
  $red: hsl(348, 100%, 61%),

  // Typography
  $family-sans-serif: '"Roboto", sans-serif',
  $family-monospace: monospace,
  $render-mode: optimizeLegibility,

  // Miscellaneous
  $radius-small: 2px,
  $radius: 3px,
  $radius-large: 5px,
  $radius-rounded: 290486px,
  $speed: 0.3s,

  // Status colors
  $primary: #e74c3c,

  // Controls
  $control-padding-vertical: calc(.375em - 1px),
  $control-padding-horizontal: calc(.625em - 1px),
);

@forward "~bulma/sass/base/minireset";
@forward "./base/generic";
@forward "~bulma/sass/base/animations";
@forward "~bulma/sass/helpers";
@forward "~bulma/sass/elements/box";
@forward "./elements/button";
@forward "~bulma/sass/elements/content";
@forward "./elements/form";
@forward "~bulma/sass/elements/icon";
@forward "./elements/image";
@forward "~bulma/sass/elements/notification";
@forward "./elements/progress";
@forward "./elements/table";
@forward "./elements/tag";
@forward "~bulma/sass/elements/title";

@forward "./components/audio-player";
@forward "./components/auth";
@forward "./components/bg-image";
@forward "~bulma/sass/components/breadcrumb";
@forward "~bulma/sass/components/card";
@forward "./components/divider";
@forward "~bulma/sass/components/dropdown";
@forward "./components/filter";
@forward "./components/loader";
@forward "./components/menu";
@forward "~bulma/sass/components/message";
@forward "~bulma/sass/components/modal";
@forward "./components/navbar";
@forward "~bulma/sass/components/pagination";
@forward "./components/page-loader";
@forward "~bulma/sass/components/panel";
@forward "./components/settings";
@forward "./components/steps";
@forward "./components/tabs";
@forward "./components/tooltip";

@forward "./layout/footer";
@forward "~bulma/sass/layout/hero";
@forward "~bulma/sass/layout/level";
@forward "./layout/hero";
@forward "./layout/main";
@forward "./layout/section";

@forward "~bulma/sass/grid/columns";

@forward "bulma/sass/themes";

// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900&subset=latin-ext');
@import url('https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css');
