@use "~bulma/sass/utilities/mixins" as mix;

.audio-player {
  position: fixed;
  left: 2%;
  bottom: 2%;
  width: 350px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
  background-color: #f2f2f2;
  div {
    width: 350px;
    height: 150px;
    text-align: center;
    audio {
      height: 46px;
      margin: 2em auto;
    }
  }
  .is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    &:after {
      @include mix.loader;
      position: absolute;
      top: calc(50% - 2.5em);
      left: calc(50% - 2.5em);
      width: 5em;
      height: 5em;
      border-width: 0.25em;
    }
  }
}
