@use 'sass:color';
@use "~bulma/sass/utilities/derived-variables" as dv;
@forward "~bulma/sass/components/menu";

/** Menu **/

.menu-label {
  font-weight: 900;
  cursor: pointer;
  &:hover {
    color: #000;
  }
}

.category-list {
  li {
    a {
      display: flex;
      align-items: center;
      padding-bottom: 0.25em;
      padding-top: 0.25em;
      color: dv.$text;

      span.name {
        flex: 1;
      }

      .button {
        width: auto;
        margin-left: .25em;
      }
      border-left: 3px solid transparent;
      &.is-active {
        color: inherit;
        background: none;
        border-left: 3px solid dv.$primary;
      }
    }
    .menu-list {
      margin-right: 0;
    }
  }
}
