/** Generic **/
@use "~bulma/sass/utilities/initial-variables" as iv;
@use "~bulma/sass/utilities/derived-variables" as dv;

@use '~bulma/sass/base/generic' with (
  $body-background-color: iv.$white,
  $body-size: 14px,

  $hr-background-color: dv.$border,
  $hr-height: 1px,

  $strong-weight: iv.$weight-bold,
);

html {
  height: 100%;
  width: 100%;
  overflow-y: auto;
}

body {
  height: 100%;
  width: 100%;
}

.is-centered {
  margin: 0 auto;
}

.has-text-weight-black {
  font-weight: 900 !important;
}

.has-space-right {
  margin-right: 0.5em;
}

.has-space-left {
  margin-left: 0.5em;
}

.has-top-margin {
  margin-top: 1em;
}
