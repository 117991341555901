@use 'sass:color';
@use 'sass:list';
@use "~bulma/sass/utilities/css-variables" as cv;
@use "~bulma/sass/utilities/initial-variables" as iv;
@use "~bulma/sass/utilities/derived-variables" as dv;
@use "~bulma/sass/utilities/mixins" as mx;

$tooltip-animation-duration: .3s !default;
$tooltip-animation-transition-timing-function: linear !default;
$tooltip-arrow-size: 6px !default;
$tooltip-background-color: iv.$grey-dark !default;
$tooltip-background-opacity: 0.9 !default;
$tooltip-color: iv.$white !default;
$tooltip-font-family: dv.$family-primary !default;
$tooltip-font-size: iv.$size-7 !default;
$tooltip-max-width: 35rem !default;
$tooltip-padding: .5rem 1rem !default;
$tooltip-radius: iv.$radius-small !default;
$tooltip-z-index: 1020 !default;

@mixin tooltip-hover {
  &:hover::before,
  &:hover::after,
  &.has-tooltip-active::before,
  &.has-tooltip-active::after {
    @content;
  }
}
@mixin tooltip-arrow-top {
  &.has-tooltip-arrow {
    &::after {
      top: 0;
      right: auto;
      bottom: auto;
      left: 50%;
      margin-top: $tooltip-arrow-size * -1 + 1;
      margin-right: auto;
      margin-bottom: auto;
      margin-left: $tooltip-arrow-size * -1 + 1;
      border-color: rgba($tooltip-background-color, $tooltip-background-opacity) transparent transparent transparent;
    }
  }
}
@mixin tooltip-box-top {
  &::before {
    top: 0;
    right: auto;
    bottom: auto;
    left: 50%;
    margin-top: $tooltip-arrow-size * -1 + 1;
    margin-bottom: auto;
    transform: translate(-50%, -100%);
  }
}
@mixin tooltip-top {
  @include tooltip-arrow-top;
  @include tooltip-box-top;
}
@mixin tooltip-arrow-bottom {
  &.has-tooltip-arrow {
    &::after {
      top: auto;
      right: auto;
      bottom: -1px;
      left: 50%;
      margin-top: auto;
      margin-right: auto;
      margin-bottom: $tooltip-arrow-size * -1 + 1;
      margin-left: $tooltip-arrow-size * -1 + 1;
      border-color: transparent transparent rgba($tooltip-background-color, $tooltip-background-opacity) transparent;
    }
  }
}
@mixin tooltip-box-bottom {
  &::before {
    top: auto;
    right: auto;
    bottom: 0;
    left: 50%;
    margin-top: auto;
    margin-bottom: $tooltip-arrow-size * -1 + 1;
    transform: translate(-50%, 100%);
  }
}
@mixin tooltip-bottom {
  @include tooltip-arrow-bottom;
  @include tooltip-box-bottom;
}

@mixin tooltip-arrow-left {
  &.has-tooltip-arrow {
    &::after {
      top: auto;
      right: auto;
      bottom: 50%;
      left: 0;
      margin-top: auto;
      margin-right: auto;
      margin-bottom: $tooltip-arrow-size * -1;
      margin-left: $tooltip-arrow-size * -1 + 1;
      border-color: transparent transparent transparent rgba($tooltip-background-color, $tooltip-background-opacity);
    }
  }
}
@mixin tooltip-box-left {
  &::before {
    top: auto;
    right: auto;
    bottom: 50%;
    left: $tooltip-arrow-size * -1 + 1;
    transform: translate(-100%, 50%);
  }
}
@mixin tooltip-left {
  @include tooltip-arrow-left;
  @include tooltip-box-left;
}

@mixin tooltip-arrow-right {
  &.has-tooltip-arrow {
    &::after {
      top: auto;
      right: 0;
      bottom: 50%;
      left: auto;
      margin-top: auto;
      margin-right: $tooltip-arrow-size * -1;
      margin-bottom: $tooltip-arrow-size * -1;
      margin-left: auto;
      border-color: transparent rgba($tooltip-background-color, $tooltip-background-opacity) transparent transparent;
    }
  }
}
@mixin tooltip-box-right {
  &::before {
    top: auto;
    right: $tooltip-arrow-size * -1 + 1;
    bottom: 50%;
    left: auto;
    margin-top: auto;
    transform: translate(100%, 50%);
  }
}
@mixin tooltip-right {
  @include tooltip-arrow-right;
  @include tooltip-box-right;
}
@mixin tooltip-direction($direction) {
  @if $direction == 'top' {
    @include tooltip-top;
  }	@else if $direction == 'right' {
    @include tooltip-right;
  }	@else if $direction == 'bottom' {
    @include tooltip-bottom;
  }	@else if $direction == 'left' {
    @include tooltip-left;
  }
}

@mixin tooltip-fade {
  &::before,
  &::after {
    transition: opacity $tooltip-animation-duration $tooltip-animation-transition-timing-function, visibility $tooltip-animation-duration $tooltip-animation-transition-timing-function;
  }
}

@mixin responsiveness {
  @each $direction in top, right, bottom, left {
    &.has-tooltip-#{$direction}-mobile {
      @include mx.mobile {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-tablet {
      @include mx.tablet {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-tablet-only {
      @include mx.tablet-only {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-touch {
      @include mx.touch {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-desktop {
      @include mx.desktop {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-desktop-only {
      @include mx.desktop-only {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-until-widescreen {
      @include mx.until-widescreen {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-widescreen {
      @include mx.widescreen {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-widescreen-only {
      @include mx.widescreen-only {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-until-fullhd {
      @include mx.until-fullhd {
        @include tooltip-direction(#{$direction});
      }
    }
    &.has-tooltip-#{$direction}-fullhd {
      @include mx.fullhd {
        @include tooltip-direction(#{$direction});
      }
      // Hidden breakpoints
    }
  }
  &.has-tooltip-hidden-mobile {
    @include mx.mobile {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-tablet {
    @include mx.tablet {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-tablet-only {
    @include mx.tablet-only {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-touch {
    @include mx.touch {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-desktop {
    @include mx.desktop {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-desktop-only {
    @include mx.desktop-only {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-until-widescreen {
    @include mx.until-widescreen {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-widescreen {
    @include mx.widescreen {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-widescreen-only {
    @include mx.widescreen-only {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-until-fullhd {
    @include mx.until-fullhd {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
    }
  }
  &.has-tooltip-hidden-fullhd {
    @include mx.fullhd {
      &::after,
      &::before {
        opacity: 0 !important;
        display: none !important;
      }
      // Text alignment breakpoints
    }
  }
  @each $direction in (left, left), (centered, center), (right, right) {
    $dir: list.nth($direction, 1);
    $text: list.nth($direction, 2);
    &.has-tooltip-text-#{$dir}-mobile {
      @include mx.mobile {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-tablet {
      @include mx.tablet {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-tablet-only {
      @include mx.tablet-only {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-touch {
      @include mx.touch {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-desktop {
      @include mx.desktop {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-desktop-only {
      @include mx.desktop-only {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-until-widescreen {
      @include mx.until-widescreen {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-widescreen {
      @include mx.widescreen {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-widescreen-only {
      @include mx.widescreen-only {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-until-fullhd {
      @include mx.until-fullhd {
        &::before {
          text-align: #{$text};
        }
      }
    }
    &.has-tooltip-text-#{$dir}-fullhd {
      @include mx.fullhd {
        &::before {
          text-align: #{$text};
        }
      }
    }
  };
}

@mixin tooltip-arrow {
  &::after {
    box-sizing: border-box;
    color: $tooltip-color;
    display: inline-block;
    font-family: $tooltip-font-family;
    font-size: $tooltip-font-size;
    hyphens: auto;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    z-index: $tooltip-z-index;
    content: '';
    border-style: solid;
    border-width: $tooltip-arrow-size;
    border-color: rgba($tooltip-background-color, $tooltip-background-opacity) transparent transparent transparent;
    margin-bottom: $tooltip-arrow-size * -1 + 1;
  }
  @include tooltip-arrow-top;
}
@mixin tooltip-box {
  &::before {
    box-sizing: border-box;
    color: $tooltip-color;
    display: inline-block;
    font-family: $tooltip-font-family;
    font-size: $tooltip-font-size;
    hyphens: auto;
    opacity: 0;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    visibility: hidden;
    z-index: $tooltip-z-index;
    background: rgba($tooltip-background-color, $tooltip-background-opacity);
    border-radius: $tooltip-radius;
    content: attr(data-tooltip);
    padding: $tooltip-padding;
    text-overflow: ellipsis;
    white-space: pre;
  }
  @include tooltip-box-top;
}
@mixin tooltip-multiline {
  &::before {
    height: auto;
    width: $tooltip-max-width;
    max-width: $tooltip-max-width;
    text-overflow: clip;
    white-space: pre-wrap;
    word-break: keep-all;
  }
}
[data-tooltip] {
  &:not(.is-loading),
  &:not(.is-disabled),
  &:not([disabled]) {
    cursor: pointer;
    overflow: visible;
    position: relative;

    @include tooltip-box;
    &.has-tooltip-arrow {
      @include tooltip-arrow;
    }
    &.has-tooltip-bottom {
      @include tooltip-direction('bottom');
    }
    &.has-tooltip-left {
      @include tooltip-direction('left');
    }
    &.has-tooltip-right {
      @include tooltip-direction('right');
    }
    &.has-tooltip-multiline {
      @include tooltip-multiline;
    }
    &.has-tooltip-text-left {
      &::before {
        text-align: left;
      }
    }
    &.has-tooltip-text-centered {
      &::before {
        text-align: center;
      }
    }
    &.has-tooltip-text-right {
      &::before {
        text-align: right;
      }
    }
    @each $name, $pair in dv.$colors {
      $color: #{cv.getVar($name)};
      $color-invert: #{cv.getVar($name, "", "-invert")};
      &.has-tooltip-#{$name} {
        &::after {
          border-color: rgba($color, $tooltip-background-opacity) transparent transparent transparent !important;
        }
        &.has-tooltip-bottom {
          &::after {
            border-color: transparent transparent rgba($color, $tooltip-background-opacity) transparent !important;
          }
        }
        &.has-tooltip-left {
          &::after {
            border-color: transparent transparent transparent rgba($color, $tooltip-background-opacity) !important;
          }
        }
        &.has-tooltip-right {
          &::after {
            border-color: transparent rgba($color, $tooltip-background-opacity) transparent transparent !important;
          }
        }
        &:before {
          background-color: rgba($color, $tooltip-background-opacity);
          color: $color-invert;
        }
      }
    }
    @include tooltip-hover {
      opacity: 1;
      visibility: visible;
    }
    &.has-tooltip-fade {
      @include tooltip-fade;
    }
    @include responsiveness;
  }
}
span {
  &[data-tooltip] {
    border-bottom: 1px dashed iv.$grey-lighter;
    @each $name, $pair in dv.$colors {
      $color: #{cv.getVar($name)};
      $color-invert: #{cv.getVar($name, "", "-invert")};
      &.has-tooltip-#{$name} {
        border-bottom-color: color.adjust(iv.$grey-lighter, $lightness: 5%);
      }
    }
  }
}
.control {
  span {
    &[data-tooltip] {
      border-bottom: none;
    }
  }
}
