@use "~bulma/sass/elements/image";
@use "bulma/sass/utilities/extends";

.image {
  &.is-loading {
    position: relative;

    &::before {
      @extend %overlay;
      content: " Fájl feltöltése folyamatban ";
      display: flex;
      z-index: 1;
      color: white;
      justify-content: center;
      align-items: center;
      font-size: 1.6rem;
      background-color: rgba(0, 0, 0, .6);
    }

    img {
      position: relative;
      z-index: 0;
    }
  }
}
