@use 'sass:color';
@use "~bulma/sass/utilities/initial-variables" as iv;

/** Section **/


@use '~bulma/sass/layout/section.scss' with (
  $section-padding: 2rem,
  $section-padding-medium: 9rem 1.5rem,
  $section-padding-large: 18rem 1.5rem,
);

.section-content {
  background-color: color.adjust(iv.$grey-lighter, $lightness: 10%);
  height: calc(100vh - 3.25rem);
  overflow-y: auto;
  .welcome {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
