@use "sass:list";
@use "~bulma/sass/utilities/css-variables" as cv;
@use "~bulma/sass/utilities/initial-variables" as iv;
@use "~bulma/sass/utilities/derived-variables" as dv;
@use "~bulma/sass/utilities/functions" as fn;
@use "~bulma/sass/utilities/mixins" as mx;

$divider-background-color: iv.$grey-lighter !default;
$divider-thickness: .1rem !default;
$divider-font-size: iv.$size-7 !default;
$divider-color: iv.$grey-light !default;
$divider-content-background-color : iv.$white !default;

.is-divider, .is-divider-vertical {
  display: block;
  position: relative;
  border-top: $divider-thickness solid $divider-background-color;
  height: $divider-thickness;
  margin: 2rem 0;
  text-align: center;
}

.is-divider[data-content]::after, .is-divider-vertical[data-content]::after {
  background: $divider-content-background-color;
  color: $divider-color;
  content: attr(data-content);
  display: inline-block;
  font-size: $divider-font-size;
  padding: .4rem .8rem;
  transform: translateY(-1.1rem);
  text-align: center;
}

.is-divider-vertical {
  @include mx.tablet {
    display: block;
    flex: none;
    width: auto;
    height: auto;
    padding: 2rem;
    margin: 0;
    position: relative;
    border-top: none;
    min-height: 2rem;
  }

  &::before {
    border-left: $divider-thickness solid $divider-background-color;
    bottom: 1rem;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 1rem;
    transform: translateX(-50%);
  }

  &[data-content]::after {
    position: absolute;
    left: 50%;
    top: 50%;
    padding: .1rem;
    transform: translateY(-50%) translateX(-50%);
  }
}

.is-divider,
.is-divider-vertical {
  @each $name, $pair in dv.$colors {
    &.#{iv.$class-prefix}is-#{$name} {
      $color: #{cv.getVar($name)};
      $color-invert: #{cv.getVar($name, "", "-invert")};
      &.is-#{$name} {
        border-top-color: $color;
      }

      &[data-content]::after {
        background: $color-invert;
        color: $color;
      }

      &::before {
        border-left-color: $color;
      }
    }
  }
}
