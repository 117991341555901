/** Breadcrumb **/

.bg-image {
  display: block;
  overflow: hidden;
  position: relative;

  img {
    bottom: 0;
    height: 100%;
    left: 0;
    object-fit: cover;
    object-position: center;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
  }

  // ie + edge fix

  .ie &,
  .edge & {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    img {
      display: none !important;
      height: 0 !important;
      width: 0 !important;
    }

    .bg-image-text {
      display: block !important;
      height: 0 !important;
      overflow: hidden !important;
      text-indent: -20000px !important;
      width: 0 !important;
    }
  }

  // ratios

  &.bg-image-1by1,
  &.bg-image-square {
    padding-bottom: 100%;
  }

  &.bg-image-4by3 {
    padding-bottom: 75%;
  }

  &.bg-image-3by2 {
    padding-bottom: 66.66666%;
  }

  &.bg-image-5by3 {
    padding-bottom: 60%;
  }

  &.bg-image-16by9 {
    padding-bottom: 56.25%;
  }

  &.bg-image-3by1 {
    padding-bottom: 33.33333%;
  }
}
