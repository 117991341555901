/** Navbar **/
@use "~bulma/sass/utilities/mixins" as mx;
@forward "~bulma/sass/components/navbar";

.navbar {
  &-logo {
    img {
      height: auto;
      max-height: none !important;
      max-width: none;
      width: 150px;
    }
  }

  .hamburger {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: auto;
    text-align: center;

    @include mx.desktop {
      display: none;
    }
  }

  .navbar-dropdown {
    max-height: 90vh;
    overflow-y: auto;
  }
}
