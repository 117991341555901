@use 'sass:color';
@use "~bulma/sass/utilities/initial-variables" as iv;

@keyframes slidein {
  from {
    transform: translateX(25vw);
  }
  to {
    transform: translateX(0);
  }
}
@keyframes fadein {
  from {
    background-color: rgba(0, 0, 0, 0);
  }
  to {
    background-color: rgba(0, 0, 0, 0.8);
  }
}
.filter {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: none;
  z-index: 100;
  &.is-active {
    display: block;
    .filter-background {
      animation: fadein 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
    .filter-content {
      animation: slidein 0.2s cubic-bezier(0.4, 0, 0.2, 1) forwards;
    }
  }
  &-background {
    z-index: 0;
    background-color: rgba(0, 0, 0, 0);
  }
  &-content {
    margin-left: auto;
    z-index: 102;
    width: 25vw;
    height: 100vh;
    transform: translateX(25vw);
    transition: all 0.3s;
    background-color: iv.$white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    > .section {
      &:first-child {
        background-color: color.adjust(iv.$grey-lighter, $lightness: 10%);
        border-bottom: 1px solid iv.$grey-lighter;
      }
      &:nth-child(2) {
        flex: 2 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
      }
      &:last-child {
        background-color: color.adjust(iv.$grey-lighter, $lightness: 10%);
        border-top: 1px solid iv.$grey-lighter;
      }
    }
  }
}
