/** Tabs **/
@use '~bulma/sass/utilities/derived-variables' as dv;
@use '~bulma/sass/components/tabs.scss';

/* stylelint-disable */

.tab-content {
  &__inner {
    display: none;
    padding: tabs.$tabs-link-padding;
    padding-top: 0;
  }

  input[type='radio'] {
    display: none;

    &:checked {
      + div {
        display: block;
      }
    }
  }
}

.tabs {
  input[type='radio'] {
    display: none;

    &:checked {
      + li {
        @extend .is-active;
      }
    }
  }
}

/* stylelint-enable */


.tab-content {
  // display: none;
  padding: 1.25em;
  border-left: 1px solid dv.$border;
  border-right: 1px solid dv.$border;
  border-bottom: 1px solid dv.$border;
  // &:target {
  //   display: block;
  // }
}
