@use "~bulma/sass/utilities/initial-variables" as iv;
@forward "~bulma/sass/form";

.field {
  &.has-top-margin {
    margin-top: 1em;
  }
  .help {
    &.is-danger,
    &.is-success {
      font-size: 1rem;
    }
  }

  &.audio-player {
    min-height: 50px;
  }
}

.label {
  &:not(:last-child) {
    padding-bottom: 0.25em;
    margin-bottom: 0;
  }
}

textarea {
  min-height: 100px;
  resize: vertical;
}

.file {
  .file-name {
    background-color: iv.$white;
  }
}

.react-autosuggest__input {
  @extend .input;
}

.is-checkbox-group {
  height: 150px;
  overflow-y: scroll;
}

.is-sub {
  padding: 0 .5em;
}

.checkbox {
  input {
    margin-right: .25em;
  }
}

.autocomplete {
  .control {
    position: relative;

    .results {
      @extend .input;
      position: absolute;
      z-index: 1;
      max-height: 100px;
      height: auto;
      top: 100%;
      left: 0;
      display: inline-block;

      li {
        cursor: pointer;
        user-select: none;
        padding: .3em .15em;

        &:hover {
          background: rgba(iv.$black, 0.05);
          width: 100%;
        }
      }
    }
  }
}
